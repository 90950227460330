import React from 'react';
import { MaskContainer } from './styles';

export const CurveMask = () => {
  return (
    <MaskContainer data-test={`mask-container`}>
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="curve-mask-top-desktop" clipPathUnits="objectBoundingBox">
            <path d="M1,0.762 V0.816 C1,0.816,0.77,1,0.498,1 C0.226,1,0,0.816,0,0.816 V0.762 V0 H1 V0.762" />
          </clipPath>
          <clipPath id="curve-mask-bottom-desktop" clipPathUnits="objectBoundingBox">
            <path d="M 0 0 H 1 V 0.84 C 1 0.84 0.771 1 0.5 1 C 0.226 1 0 0.84 0 0.84 V 0" />
          </clipPath>
          <clipPath id="curve-mask-desktop-inverted" clipPathUnits="objectBoundingBox">
            <path d="M0,1 L0,0.074 C0,0.074,0.226,0,0.498,0 C0.77,0,1,0.074,1,0.074 V1 C1,1,0.77,0.926,0.498,0.926 C0.226,0.926,0,1,0,1" />
          </clipPath>
          <clipPath id="curve-mask-top-tablet" clipPathUnits="objectBoundingBox">
            <path d="M 0 0 H 1.0047 V 0.76 C 1.0047 0.76 0.7374 0.89 0.506 0.89 C 0.2673 0.89 0 0.76 0 0.76 V 0" />
          </clipPath>
          <clipPath id="curve-mask-bottom-tablet" clipPathUnits="objectBoundingBox">
            <path d="M 0 0 H 1.0047 V 0.78 C 1.0047 0.78 0.7374 0.88 0.506 0.88 C 0.2673 0.88 0 0.78 0 0.78 V 0" />
          </clipPath>
          <clipPath id="curve-mask-top-mobile" clipPathUnits="objectBoundingBox">
            <path d="M 0 0 H 1.0047 V 0.9 c 0 0 -0.2673 0.07 -0.4987 0.07 c -0.2387 0 -0.506 -0.07 -0.506 -0.07 V 0" />
          </clipPath>
          <clipPath id="curve-mask-bottom-mobile" clipPathUnits="objectBoundingBox">
            <path d="M 0 0 H 1.0047 v 0.93 C 1.0047 0.93 0.7374 0.98 0.506 0.98 C 0.2673 0.98 0 0.93 0 0.93 V 0" />
          </clipPath>
          <clipPath id="curve-mask-mobile-inverted" clipPathUnits="objectBoundingBox">
            <path d="M 0 1 L 0 0.074 C 0 0.07 0.226 0.04 0.498 0.04 C 0.77 0.04 1 0.074 1 0.074 V 1 C 1 1 0.77 0.966 0.498 0.966 C 0.226 0.966 0 1 0 1" />
          </clipPath>
        </defs>
      </svg>
    </MaskContainer>
  );
};
