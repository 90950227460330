import React from 'react';
import clsx from 'clsx';
import { ICorpVideoHeroProps } from '../types';
import {
  BannerContentWrapper,
  BannerImage,
  BannerImageGradientMobile,
  BannerWrapper,
  Link,
  ModalButtonWrapper,
} from '../styles';
import { getPictureSources } from 'lib/utils/helpers';
import { Grid, GridItem, GridRow } from 'components/Grid';
import FadeInSection from 'components/FadeInSection';
import { Text, Title } from 'components/Typography';
import { ModalBox } from 'components/ModalBox';
import { PlayButton } from 'components/Icons/icons';
import { getCopy } from 'lib/utils/getCopy';
import { CorporateButton } from 'components/CorporateButton';

export const BannerContent: React.FC<ICorpVideoHeroProps> = ({
  bannerImage: image,
  mobileImage,
  title,
  superTitle,
  description,
  videoId,
  imageAlt,
  textColor,
  darkOverlay,
  contentAlignment = 'Center',
  verticalAlignment = 'middle',
  linkComponent,
  copy,
}: ICorpVideoHeroProps) => {
  return (
    <BannerWrapper
      className={clsx({
        'justify-center': verticalAlignment === 'middle',
        'justify-start': verticalAlignment === 'top',
        'justify-end': verticalAlignment === 'bottom',
        'items-center': contentAlignment === 'Center',
        'items-start': contentAlignment === 'Left',
        'items-end': contentAlignment === 'Right',
      })}
    >
      <div
        className={`-z-1 block md:hidden w-full h-full hero-banner-image Tall absolute top-0 left-0`}
        data-test={[`mobile-image-wrapper`]}
      >
        <BannerImageGradientMobile className="w-full h-full" darkOverlay={darkOverlay} />

        <BannerImage
          sources={mobileImage?.mobile ? getPictureSources(mobileImage) : getPictureSources(image)}
          data-test={[`mobile-image`]}
          alt={imageAlt}
        />
      </div>
      <div
        className="-z-1 hidden md:block w-full h-full hero-desktop-image absolute top-0 left-0"
        data-test={[`desktop-image-wrapper`]}
      >
        <BannerImageGradientMobile className="w-full h-full" darkOverlay={darkOverlay} />
        <BannerImage
          sources={getPictureSources(image)}
          data-test={[`desktop-image`]}
          alt={imageAlt}
        />
      </div>
      <div
        style={{ zIndex: 1 }}
        className={clsx({
          'pt-8': verticalAlignment === 'top',
          'pb-4': verticalAlignment === 'bottom',
        })}
      >
        <Grid>
          <GridRow columns={16}>
            <GridItem colStart={2} colSpan={14}>
              <BannerContentWrapper
                data-test="content-wrapper"
                className={clsx({
                  'text-center': contentAlignment === 'Center',
                  'text-center md:text-start': contentAlignment === 'Left',
                  'text-center md:text-end': contentAlignment === 'Right',
                  'text-white': textColor === 'white',
                  'text-black': textColor === 'black',
                  'text-corporate-blue': !textColor,
                })}
              >
                <div
                  data-test={[`content`]}
                  className={clsx({
                    'pb-24': verticalAlignment === 'middle',
                  })}
                >
                  {superTitle && (
                    <FadeInSection delay="600ms">
                      <Text className="" data-test={[`title`]} tag="p" type="lead">
                        {superTitle}
                      </Text>
                    </FadeInSection>
                  )}
                  {title && (
                    <FadeInSection delay="600ms">
                      <Title className="" data-test={[`title`]} tag="h1" type="lg">
                        {title}
                      </Title>
                    </FadeInSection>
                  )}
                  {description && (
                    <FadeInSection delay="600ms">
                      <Text className="" data-test={[`title`]} tag="p" type="md">
                        {description}
                      </Text>
                    </FadeInSection>
                  )}
                  {videoId && (
                    <ModalBox
                      videoID={videoId}
                      playButton={
                        <ModalButtonWrapper className="mt-5">
                          <PlayButton className="shrink-0" />
                          <Text className="shrink-0 " tag="span" type="lead">
                            {getCopy('watch_video', copy)}
                          </Text>
                        </ModalButtonWrapper>
                      }
                    />
                  )}
                  {linkComponent && (
                    <Link href={linkComponent?.url ?? ''}>
                      <CorporateButton
                        color="tertiary"
                        label={linkComponent?.displayName}
                        className={clsx('mx-auto mt-10', {
                          'md:absolute mx-auto md:mx-0 md:right-44': contentAlignment === 'Right',
                        })}
                      />
                    </Link>
                  )}
                </div>
              </BannerContentWrapper>
            </GridItem>
          </GridRow>
        </Grid>
      </div>
    </BannerWrapper>
  );
};
