import React from 'react';
import { Container } from './styles';
import { ICorpVideoHeroWrapperContainerProps } from './types';

export const ContainerStyle = `relative my-0 p-0 min-w-full z-40 overflow-hidden`;

export const HeroWrapperContainer = ({ children }: ICorpVideoHeroWrapperContainerProps) => (
  <Container data-testid="hero-wrapper-container" className={ContainerStyle} data-print="hide">
    {children}
  </Container>
);
